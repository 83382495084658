import { ContextLinkList } from '@bbx/common/types/contextLinks'
import { TaggingData } from '@wh/common/chapter/types/taggingData'

// searchAgentList = List of all Suchagent(s)
// searchAgent = A Suchagent

export interface SearchAgentListType {
    contextLinkList: ContextLinkList
    taggingData: TaggingData
    userAlert: SearchAgentType[]
}

export interface SearchAgentOptions {
    searchAgentDescription: string
    showFrequencyOptions: boolean
    disableFrequencyOptions: boolean
    frequencyLabel: string
    searchAgentFrequencyOptions: SearchAgentFrequencyOption[]
    searchAgentChannels: SearchAgentChannel[]
}

export interface SearchAgentChannel {
    id: SearchAgentNotificationType
    description: string
}

export interface SearchAgentFrequencyOption {
    key: SearchAgentNotificationFrequency
    label: string
    channelFrequencies: SearchAgentNotificationType[]
    isDefault: boolean
    hint?: string
    tooltip?: string
}

export interface SearchAgentType {
    contextLinkList?: ContextLinkList
    description: string
    followingUser: boolean
    id: string
    orgId: number
    searchConfigDescription?: string
    searchConfigId?: number
    searchConfigParameters?: Record<string, SearchAgentConfigParam>
    searchKey?: string
    status?: number
    userAlertChannelList: UserAlertChannelListType
    userId: number
    verticalDescription?: string
    verticalId: number
    frequencyDescription?: string
}

export interface SearchAgentCreate {
    userId: number
    followingUser: boolean
    orgId: number
    verticalId: number
    description: string
    userAlertChannelList: UserAlertChannelListType
}

export interface UserAlertChannelListType {
    userAlertChannel: UserAlertChannelType[]
}

export interface SearchAgentConfigParam {
    id: string
    formatted: string
}

export type SearchAgentParams = Record<string, string | string[] | undefined>

export enum SearchAgentNotificationType {
    EMAIL = 'email',
    PUSH = 'push',
}

export enum SearchAgentNotificationFrequency {
    DAILY = 'DAILY', // deprecated, added for backwards compatibility with iad, remove after releasing WHIAD-31195
    DAILY_3X = 'DAILY_3X',
    INSTANT = 'INSTANT',
}

export interface UserAlertChannelType {
    activated: boolean
    alertId?: number
    description?: string
    endDate?: string
    id?: SearchAgentNotificationType
    lastSearchDate?: null
    userId?: number
    notificationFrequency?: SearchAgentNotificationFrequency
}

export interface SearchAgentData {
    description: string
    status?: number
    UserAlertChannelUpdateList?: UserAlertChannelListType
}
