import { SearchAgentListType, SearchAgentOptions, SearchAgentParams, SearchAgentType } from '@bbx/common/types/searchAgent'
import { UserSellerProfileData } from '@wh/common/chapter/types/user'
import { getBbxCookiesFromRequest } from '@wh/common/chapter/types/cookies'
import { Request } from 'express'
import { ContextLink } from '@bbx/common/types/contextLinks'
import { encodeURIifNecessary } from '@wh/common/chapter/lib/urlHelpers'
import { fetcher } from '@wh/common/chapter/api/fetcher'
import { urlSearchParamsFromObject } from '@wh/common/chapter/api/urlSearchParams'

// searchAgentList = List of all Suchagent(s)
// searchAgent = A Suchagent

/** Get all Suchagent */
export const getAllSearchAgent = (loginId: number, request?: Request): Promise<SearchAgentListType> => {
    return fetcher<SearchAgentListType>(`/iad/alert/user/${loginId}`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

/** Get a specific Suchagent */
export const getSearchAgent = (loginId: number, searchAgentId: string, request?: Request): Promise<SearchAgentType> => {
    return fetcher<SearchAgentType>(`/iad/alert/user/detail/${loginId}/${searchAgentId}`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

/** Delete a Suchagent */
export const deleteSearchAgent = (loginId: number, searchAgentId: string): Promise<void> => {
    return fetcher(`/iad/alert/user/${loginId}/${searchAgentId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Update the description of a Suchagent */
export const updateSearchAgentDescription = (loginId: number, searchAgentId: string, description: string): Promise<SearchAgentType> => {
    return fetcher<SearchAgentType>(`/iad/alert/user/${loginId}/${searchAgentId}/update`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify({ description: description }),
    })
}

/** Update the Suchagent */
export const updateSearchAgent = (loginId: number, searchAgent: SearchAgentType): Promise<SearchAgentType> => {
    return fetcher<SearchAgentType>(`/iad/alert/user/${loginId}/${searchAgent.id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        body: JSON.stringify(searchAgent),
    })
}

/** Create a new Suchagent */
export const createSearchAgent = (params: SearchAgentParams): Promise<SearchAgentType> => {
    return fetcher<SearchAgentType>(`/iad/alert/user?${urlSearchParamsFromObject(params)}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

export const createSearchAgentWithLink = (contextLink: ContextLink, searchAgent: SearchAgentType): Promise<SearchAgentType> => {
    return fetcher<SearchAgentType>(`/${contextLink.serviceName}${encodeURIifNecessary(contextLink.relativePath!)}`, {
        method: 'POST',
        headers: {
            'Cache-Control': 'no-cache',
        },
        credentials: 'include',
        body: JSON.stringify(searchAgent),
    })
}

/** Set the email notification status for a specific Suchagent */
export const setEmailNotificationStatus = (loginId: number, searchAgentId: string, active: boolean): Promise<void> => {
    const path = active ? 'activate' : 'deactivate'

    return fetcher(`/iad/alert/user/${loginId}/${searchAgentId}/${path}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Get all data from the profile of a seller (user) */
export const getFollowUser = (verticalId: number, orgId: number): Promise<UserSellerProfileData> => {
    return fetcher(`/iad/sellerprofile/${orgId}/${verticalId}`, {
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Follow a seller (user) */
export const followUser = (loginId: number, verticalId: number, orgId: number): Promise<SearchAgentType> => {
    return fetcher<SearchAgentType>(`/iad/sellerprofile/${orgId}/${verticalId}/follower/${loginId}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
    })
}

/** Unfollow a seller (user) */
export const unfollowUser = (
    loginId: number,
    verticalId: number,
    orgId: number,
    searchAgentId: string,
    request?: Request,
): Promise<void> => {
    return fetcher(`/iad/sellerprofile/${orgId}/${verticalId}/follower/${loginId}/${searchAgentId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Cache-Control': 'no-cache',
        },
        cookies: getBbxCookiesFromRequest(request),
    })
}

/** Get all Suchagent */
export const getSearchAgentOptions = (searchAgentCreateLink: ContextLink, request?: Request): Promise<SearchAgentOptions> => {
    return fetcher<SearchAgentOptions>(
        `/${searchAgentCreateLink.serviceName}${encodeURIifNecessary(searchAgentCreateLink.relativePath!)}`,
        {
            credentials: 'include',
            headers: {
                'Cache-Control': 'no-cache',
            },
            cookies: getBbxCookiesFromRequest(request),
        },
    )
}
