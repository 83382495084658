import React, { ChangeEvent, ReactEventHandler } from 'react'
import classNames from 'classnames'
import { IdProps, ClassNameProps, TestProps } from '@wh-components/core/common'
import { css } from 'styled-components'

interface SearchAgentToggleProps extends IdProps, ClassNameProps, TestProps {
    /** Describes if the toggle is checked */
    checked?: boolean
    /** Specifies the value of the toggle */
    value?: string
    /** Sets the toggle to disabled */
    disabled?: boolean
    /** If set the toggle is required */
    required?: boolean
    /** Specifies a name for the toggle */
    name?: string
    /**  Specify a string to be used as the accessible label */
    ariaLabel?: string
    /** Specify the ID of another element in the DOM as an element's label. */
    ariaLabelledBy?: string
    /** The onChange event fires the moment when the value of the element is changed. */
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    /** The onBlur event fires the moment that the element loses focus. */
    onBlur?: ReactEventHandler<HTMLInputElement>
    /** The onFocus event fires the moment when the element gets focus. */
    onFocus?: ReactEventHandler<HTMLInputElement>
}

/** A basic badge component that should render nicely on any platform */
export const SearchAgentToggle = ({
    id,
    testId,
    className,
    name,
    disabled,
    required,
    checked,
    value,
    ariaLabel,
    ariaLabelledBy,
    onChange,
    onBlur,
    onFocus,
}: SearchAgentToggleProps) => {
    const classes = classNames({
        'wh-search-toggle': true,
        className,
    })

    return (
        <div
            className={classes}
            css={css`
                .wh-search-toggle-input {
                    display: none;

                    &:checked {
                        & + .wh-search-toggle-btn {
                            background-color: ${(p) => p.theme.colors.adStatus.active};

                            &:after {
                                left: calc(4em + 2px);
                            }

                            span.on {
                                opacity: 1;
                            }

                            span.off {
                                opacity: 0;
                            }
                        }
                    }

                    &:disabled {
                        & + .wh-search-toggle-btn {
                            background-color: ${(p) => p.theme.colors.palette.dove};
                        }
                    }
                }

                .wh-search-toggle-btn {
                    transition: all 0.3s ease-in;
                    display: block;
                    position: relative;
                    height: 24px;
                    border-radius: 1em;
                    cursor: pointer;
                    background-color: ${(p) => p.theme.colors.palette.grey};
                    width: 80px;
                    padding: 0 ${(p) => p.theme.space.s}px;
                    font-weight: ${(p) => p.theme.fontWeights.semibold};

                    span {
                        display: block;
                        position: absolute;
                        width: 100%;
                        font-size: ${(p) => p.theme.fontSizes.s};
                        transition: opacity 0.3s ease;

                        color: ${(p) => p.theme.colors.palette.white};
                        height: 24px;
                        line-height: 24px;

                        &.on {
                            opacity: 0;
                            text-align: left;
                            padding-left: ${(p) => p.theme.space.sm}px;
                            right: 0;
                        }

                        &.off {
                            opacity: 1;
                            text-align: right;
                            padding-right: ${(p) => p.theme.space.sm}px;
                            right: 0;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        border-radius: 50%;
                        top: 4px;
                        left: 4px;
                        transition: left 0.2s ease;

                        width: 16px;
                        height: 16px;
                        background-color: ${(p) => p.theme.colors.palette.white};
                    }
                }
            `}
        >
            <input
                className="wh-search-toggle-input"
                data-testid={`${testId}-checkbox`}
                id={`wh-search-toggle-input--${id}`}
                value={value}
                disabled={disabled}
                checked={checked}
                name={name}
                type="checkbox"
                required={required}
                aria-label={ariaLabel}
                aria-labelledby={ariaLabelledBy}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
            />
            <label className="wh-search-toggle-btn" htmlFor={`wh-search-toggle-input--${id}`} data-testid={`${testId}-checkbox-label`}>
                <span className="on">Aktiv</span>
                <span className="off">Inaktiv</span>
            </label>
        </div>
    )
}
