/* eslint-disable max-lines */
import React from 'react'
import classnames from 'classnames'
import { Formik, FormikHelpers, InjectedFormikProps } from 'formik'
import { validationErrorMessages } from '@bbx/common/lib/validation'
import { removeUndefined } from '@wh/common/chapter/lib/functionalHelpers'
import { Button } from '@wh-components/core/Button/Button'
import { SearchAgentToggle } from '@bbx/search-journey/common/components/SearchAgentToggle/SearchAgentToggle'
import {
    SearchAgentNotificationFrequency,
    SearchAgentNotificationType,
    SearchAgentOptions,
    SearchAgentType,
} from '@bbx/common/types/searchAgent'
import { getDefaultNotificationFrequency, getNotificationFrequency, getNotificationStatus } from '@bbx/common/lib/searchAgentHelper'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { FormikInput } from '@wh/common/chapter/components/Formik/FormikInputs'
import { Heading } from '@wh-components/core/Heading/Heading'
import { css } from 'styled-components'
import { Text } from '@wh-components/core/Text/Text'
import ResetCircle from '@wh-components/icons/ResetCircle'
import { Box } from '@wh-components/core/Box/Box'
import { hasMaxLength, isNonEmpty } from '@wh/common/chapter/lib/validation'
import { SearchAgentFrequencyOptions } from '@bbx/search-journey/common/components/SearchAgentFrequencyOptions/SearchAgentFrequencyOptions'

interface EditSearchAgentFormProps {
    searchAgent?: SearchAgentType
    onAbort: () => void
    onSubmit: (selected: EditSearchAgentFormValues) => Promise<void>
    onDelete?: (selected: SearchAgentType) => void
    searchAgentOptions?: SearchAgentOptions | undefined | null
    isEdit?: boolean
}

export interface EditSearchAgentFormValues {
    id: string
    description: string
    emailActivated: boolean
    notificationFrequency: SearchAgentNotificationFrequency
    appActivated: boolean
}

export type EditSearchAgentField = keyof EditSearchAgentFormValues

const fieldRequiredness = (_values: EditSearchAgentFormValues): Record<EditSearchAgentField, boolean> => {
    return {
        id: true,
        description: true,
        emailActivated: true,
        appActivated: true,
        notificationFrequency: true,
    }
}

const testIdPrefix = 'search-agent-modal'

const Criteria: React.FC<{ searchAgent: SearchAgentType }> = ({ searchAgent }) => {
    return (
        <React.Fragment>
            <div className="myprofile-edit-form--box suchcriteria-box">
                <Heading
                    level={4}
                    text="Suchkriterien"
                    fontWeight="semibold"
                    css={css`
                        margin-bottom: ${(p) => p.theme.space.s}px;
                    `}
                />
                <div className="suchcriteria-box--content" data-testid={`${testIdPrefix}-search-criteria`}>
                    {searchAgent.searchConfigParameters &&
                        Object.keys(searchAgent.searchConfigParameters).map((key) => {
                            return (
                                searchAgent.searchConfigParameters && (
                                    <div
                                        key={searchAgent.searchConfigParameters[key].id}
                                        dangerouslySetInnerHTML={{ __html: searchAgent.searchConfigParameters[key].formatted }}
                                    />
                                )
                            )
                        })}
                    {searchAgent.searchConfigDescription && <div>Anzeigen: {searchAgent.searchConfigDescription}</div>}
                </div>
                <Box className="suchcriteria-box--action">
                    <Text color="palette.elephant" fontSize="s">
                        Die Suchkriterien können nicht bearbeitet werden.
                    </Text>
                </Box>
            </div>
            <hr />
        </React.Fragment>
    )
}

const getFieldToLabelMap = (
    notificationFrequencyLabel = 'Wie oft möchtest du benachrichtigt werden?',
): Record<EditSearchAgentField, string> => {
    return {
        id: '',
        description: 'Titel',
        emailActivated: '',
        notificationFrequency: notificationFrequencyLabel,
        appActivated: '',
    }
}

const EditSearchAgentInnerForm: React.FC<InjectedFormikProps<EditSearchAgentFormProps, EditSearchAgentFormValues>> = (props) => {
    const appStatusText = props.values.appActivated ? 'aktiv' : 'inaktiv'
    const appClasses = classnames({ 'myprofile-edit-form--row-action': true }, `myprofile-edit-form--row-action--${appStatusText}`)

    const isIsaEmailPresent = props.searchAgentOptions?.searchAgentFrequencyOptions
        .find((option) => option.key === SearchAgentNotificationFrequency.INSTANT)
        ?.channelFrequencies.includes(SearchAgentNotificationType.EMAIL)

    const shouldDisplaySearchAgentFrequency = props.searchAgentOptions?.showFrequencyOptions && (isIsaEmailPresent || props.isEdit)

    const fieldToLabelMap = getFieldToLabelMap(props.searchAgentOptions?.frequencyLabel)
    const emailLabel =
        props.searchAgentOptions?.searchAgentChannels.find((ch) => ch.id === SearchAgentNotificationType.EMAIL)?.description || 'E-Mail'
    const pushLabel =
        props.searchAgentOptions?.searchAgentChannels.find((ch) => ch.id === SearchAgentNotificationType.PUSH)?.description ||
        'App-Benachrichtigungen'
    const isDailySearchAgentSelectedInEdit = props.searchAgent?.userAlertChannelList?.userAlertChannel.every(
        (channel) => SearchAgentNotificationFrequency.DAILY_3X === channel.notificationFrequency,
    )
    const showEmailToggle =
        !props.isEdit ||
        isDailySearchAgentSelectedInEdit ||
        props.searchAgentOptions?.searchAgentFrequencyOptions?.every((el) =>
            el.channelFrequencies.includes(SearchAgentNotificationType.EMAIL),
        )

    const formSpecificFieldProps = {
        formProps: props,
        fieldLabelMap: fieldToLabelMap,
        fieldRequiredness: fieldRequiredness,
    }

    // use method post in order to prevent email/password field content to land in the browser url in case js breaks on the page
    return (
        <form
            className="myprofile-edit-form"
            method="post"
            onSubmit={props.handleSubmit}
            noValidate={true}
            css={css`
                ${(p) => p.theme.media.tablet} {
                    min-width: 608px;
                    max-width: 608px;
                }

                .myprofile-edit-form {
                    &-buttons-wrapper {
                        display: flex;
                        flex-direction: column-reverse;

                        ${(p) => p.theme.media.tablet} {
                            flex-direction: row;
                            justify-content: space-between;
                        }

                        &--button {
                            flex: 0 0 auto;
                            margin-bottom: ${(p) => p.theme.space.m}px;

                            &:first-of-type {
                                margin-bottom: 0;
                            }

                            ${(p) => p.theme.media.tablet} {
                                flex: 0 0 auto;
                                margin-bottom: 0;
                            }

                            &.abort-myalert-button {
                                display: none;

                                ${(p) => p.theme.media.tablet} {
                                    display: block;
                                }
                            }

                            &.delete-myalert-button {
                                ${(p) => p.theme.media.tablet} {
                                    display: none;
                                }
                            }
                        }
                    }

                    &--scrollable {
                        ${(p) => p.theme.media.tablet} {
                            max-height: 450px;
                            overflow-y: auto;
                            /* enable momentum/inertia scrolling on iOS */
                            -webkit-overflow-scrolling: touch;
                            margin: 0 0 ${(p) => p.theme.space.s}px 0;
                        }
                    }

                    &--box {
                        padding: 0 ${(p) => p.theme.space.s}px ${(p) => p.theme.space.m}px 3px;

                        .suchcriteria-box {
                            &--content {
                                margin-bottom: ${(p) => p.theme.space.s}px;
                            }
                        }
                    }

                    &--table {
                        display: flex;
                        flex-direction: column;
                    }

                    &--row {
                        display: flex;
                        margin-bottom: ${(p) => p.theme.space.m}px;
                        flex-wrap: wrap;
                        align-items: center;

                        &:last-of-type {
                            margin-bottom: 0;
                        }

                        ${(p) => p.theme.media.tablet} {
                            flex-wrap: nowrap;
                        }

                        &-label {
                            flex: 1 0 auto;

                            ${(p) => p.theme.media.tablet} {
                                flex: 0 0 30%;
                            }
                        }

                        &-appstatus {
                            font-weight: ${(p) => p.theme.fontWeights.semibold};
                        }

                        &-action {
                            display: flex;
                            flex: 0 1 auto;
                            text-transform: capitalize;

                            &--aktiv {
                                color: ${(p) => p.theme.colors.adStatus.active};
                            }

                            &--inaktiv {
                                color: ${(p) => p.theme.colors.palette.grey};
                            }

                            ${(p) => p.theme.media.tablet} {
                                flex: 0 0 100px;
                            }
                        }

                        &-info {
                            flex: 1 0 100%;
                            color: ${(p) => p.theme.colors.palette.elephant};
                            margin-top: ${(p) => p.theme.space.s}px;

                            ${(p) => p.theme.media.tablet} {
                                flex: 0 0 auto;
                                margin-top: 0;
                            }
                        }
                    }
                }

                hr {
                    margin: 0;
                    margin-bottom: ${(p) => p.theme.space.m}px;
                }
            `}
        >
            <div className="myprofile-edit-form--scrollable">
                <div className="myprofile-edit-form--box">
                    <FormikInput
                        field="description"
                        {...formSpecificFieldProps}
                        Icon={ResetCircle}
                        onIconClick={() => {
                            props.setFieldValue('description', '')
                        }}
                    />
                </div>
                {props.searchAgentOptions && shouldDisplaySearchAgentFrequency && (
                    <SearchAgentFrequencyOptions
                        marginBottom="m"
                        isEdit={props.isEdit}
                        notificationFrequency={props.values.notificationFrequency}
                        searchAgentOptions={props.searchAgentOptions}
                        fieldRequiredness={fieldRequiredness}
                        fieldLabelMap={fieldToLabelMap}
                        formProps={props}
                    />
                )}

                <hr />
                <div className="myprofile-edit-form--box">
                    <div className="myprofile-edit-form--table">
                        {showEmailToggle && (
                            <div className="myprofile-edit-form--row">
                                <div className="myprofile-edit-form--row-label">
                                    <Heading level={4} fontWeight={600} text={emailLabel} />
                                </div>
                                <div className="myprofile-edit-form--row-action">
                                    <SearchAgentToggle
                                        onChange={(e) => {
                                            if (props.values.emailActivated) {
                                                callActionEvent('my_search_agents_deactivate', undefined)
                                            } else {
                                                callActionEvent('my_search_agents_activate', undefined)
                                            }
                                            props.handleChange(e)
                                        }}
                                        id="emailToggle"
                                        name="emailActivated"
                                        testId={`${testIdPrefix}-email-toggle`}
                                        checked={props.values.emailActivated}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="myprofile-edit-form--row">
                            <div className="myprofile-edit-form--row-label">
                                <Heading level={4} fontWeight={600} text={pushLabel} />
                            </div>
                            <div className={appClasses}>
                                <Text className="myprofile-edit-form--row-appstatus" fontSize="s" testId={`${testIdPrefix}-app-status`}>
                                    {appStatusText}
                                </Text>
                            </div>
                            <div className="myprofile-edit-form--row-info">
                                <Text fontSize="s">Diese Einstellung kannst du in der App bearbeiten.</Text>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                {props.searchAgent?.searchConfigParameters && !props.searchAgent.followingUser && (
                    <Criteria searchAgent={props.searchAgent} />
                )}
            </div>
            <div className="myprofile-edit-form-buttons-wrapper">
                <div className="myprofile-edit-form-buttons-wrapper--button abort-myalert-button">
                    <Button
                        name="cancel"
                        size="large"
                        color="complimentary"
                        onClick={props.onAbort}
                        disabled={props.isSubmitting}
                        width="100%"
                        testId={`${testIdPrefix}-abort-button`}
                    >
                        Abbrechen
                    </Button>
                </div>
                {props.onDelete && (
                    <div className="myprofile-edit-form-buttons-wrapper--button delete-myalert-button">
                        <Button
                            name="delete"
                            size="large"
                            variant="outline"
                            onClick={() => props.onDelete && props.searchAgent && props.onDelete(props.searchAgent)}
                            disabled={props.isSubmitting}
                            width="100%"
                            testId={`${testIdPrefix}-delete-button`}
                        >
                            Suchagent löschen
                        </Button>
                    </div>
                )}
                <div className="myprofile-edit-form-buttons-wrapper--button">
                    <Button
                        name="save"
                        size="large"
                        type="submit"
                        disabled={props.isSubmitting}
                        width="100%"
                        testId={`${testIdPrefix}-save-button`}
                    >
                        Suchagent speichern
                    </Button>
                </div>
            </div>
        </form>
    )
}
const validateEditSearchAgentForm = (values: EditSearchAgentFormValues) => {
    return removeUndefined({
        description: isNonEmpty(values.description)
            ? hasMaxLength(255, values.description)
                ? undefined
                : validationErrorMessages.MAX_255_CHARS_COUNT_ERROR
            : validationErrorMessages.EMPTY_ERROR,
    })
}

export const EditSearchAgentForm = (props: EditSearchAgentFormProps) => (
    <Formik
        initialValues={{
            id: props.searchAgent ? props.searchAgent.id : '',
            description: props.searchAgent ? props.searchAgent.description : props.searchAgentOptions?.searchAgentDescription ?? '',
            emailActivated: props.searchAgent ? getNotificationStatus(props.searchAgent, SearchAgentNotificationType.EMAIL) : true,
            appActivated: props.searchAgent ? getNotificationStatus(props.searchAgent, SearchAgentNotificationType.PUSH) : false,
            notificationFrequency: props.searchAgent
                ? getNotificationFrequency(props.searchAgent, SearchAgentNotificationType.EMAIL)
                : getDefaultNotificationFrequency(props.searchAgentOptions),
        }}
        validateOnBlur={false}
        validateOnChange={true}
        validate={validateEditSearchAgentForm}
        onSubmit={async (values, formikHelpers: FormikHelpers<EditSearchAgentFormValues>) => {
            if (props.onSubmit) {
                await props.onSubmit(values)
                formikHelpers.setSubmitting(false)
            }
        }}
    >
        {(formikProps) => {
            return <EditSearchAgentInnerForm {...formikProps} {...props} />
        }}
    </Formik>
)
