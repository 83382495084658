import React, { FunctionComponent } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { SearchAgentNotificationFrequency, SearchAgentOptions } from '@bbx/common/types/searchAgent'
import { Divider } from '@wh-components/core/Divider/Divider'
import { FormikBubbleRadioGroup } from '@wh/common/chapter/components/Formik/FormikInputs'
import { NewFeatureTooltip } from '@wh-components/core/Tooltip/NewFeatureTooltip'
import { theme } from '@wh-components/core/theme'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { getSearchAgentFrequencyProperty } from '@bbx/common/lib/searchAgentHelper'
import { Label } from '@wh-components/core/FormElements/Label/Label'
import { FormikProps } from 'formik'
import {
    EditSearchAgentField,
    EditSearchAgentFormValues,
} from '@bbx/search-journey/common/components/EditSearchAgentForm/EditSearchAgentForm'

interface SearchAgentFrequencyOptionsProps {
    isEdit?: boolean | undefined
    notificationFrequency: SearchAgentNotificationFrequency
    searchAgentOptions: SearchAgentOptions
    fieldLabelMap: Record<EditSearchAgentField, string>
    fieldRequiredness: (_values: EditSearchAgentFormValues) => Record<EditSearchAgentField, boolean>
    formProps: FormikProps<EditSearchAgentFormValues>
}

export const SearchAgentFrequencyOptions: FunctionComponent<SearchAgentFrequencyOptionsProps & BoxProps> = ({
    isEdit,
    notificationFrequency,
    searchAgentOptions,
    ...props
}) => {
    const hintText = getSearchAgentFrequencyProperty(searchAgentOptions.searchAgentFrequencyOptions, notificationFrequency, 'hint')

    const tooltipText = getSearchAgentFrequencyProperty(
        searchAgentOptions.searchAgentFrequencyOptions,
        SearchAgentNotificationFrequency.INSTANT,
        'tooltip',
    )

    return (
        <Box {...props}>
            {isEdit ? (
                <Box>
                    <Label label={props.fieldLabelMap.notificationFrequency} marginBottom="xs">
                        <Text>
                            {getSearchAgentFrequencyProperty(
                                searchAgentOptions.searchAgentFrequencyOptions,
                                notificationFrequency,
                                'label',
                            )}
                        </Text>
                    </Label>
                    <Text color="palette.elephant" fontSize="s">
                        {hintText}
                    </Text>
                </Box>
            ) : (
                <Box>
                    <Divider />
                    <FormikBubbleRadioGroup
                        field="notificationFrequency"
                        radios={searchAgentOptions.searchAgentFrequencyOptions.map((option) => {
                            return {
                                value: option.key,
                                label: option.label,
                            }
                        })}
                        fieldRequiredness={props.fieldRequiredness}
                        fieldLabelMap={props.fieldLabelMap}
                        formProps={props.formProps}
                    />
                    {tooltipText && (
                        <NewFeatureTooltip
                            localStorageId="isaTooltip"
                            // tooltip should be placed above the modal which has z-index 600
                            // @ts-ignore
                            zIndex={theme.zIndices.modal + 10}
                            expireDate={new Date('2024-03-31T23:59:59')}
                            placement="bottom-start"
                            topContent={
                                <Text
                                    width="90%"
                                    dangerouslySetInnerHTML={{
                                        __html: tooltipText,
                                    }}
                                />
                            }
                            bottomContent="Alles Klar"
                        >
                            <Text
                                css={css`
                                    position: relative;
                                    left: 170px;
                                    top: ${notificationFrequency === SearchAgentNotificationFrequency.INSTANT
                                        ? '-20px'
                                        : '0'}; // hint offset
                                `}
                            />
                        </NewFeatureTooltip>
                    )}
                    {hintText && (
                        <Text color="palette.elephant" fontSize="s">
                            {hintText}
                        </Text>
                    )}
                </Box>
            )}
        </Box>
    )
}
